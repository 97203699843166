import React from 'react';
import Axios from "axios";
import BaseInput from '../../Common/Forms/BaseInput';
import BaseButton from '../../Common/Forms/BaseButton';
import EyeIcon from '../../../assets/icons/common/eyeOpen.svg';
import ArrowIcon from '../../../assets/icons/common/arrow.svg';
import { withRouter } from 'react-router';
const Validator = require('Validator');

class Form extends React.Component {
    constructor(props) {
        super()
        this.state = {
            email: '',
            password: '',
            showPassword: false,
            errorMessages: {
                email: '',
                password: '',
            },
            liveErrorCheck: false,
        }   
    }
    handleInputs = (name, val) => {
        this.setState({
            [name]: val,
        });
        if (this.state.liveErrorCheck) this.validate();
    }

    togglePassword = () => this.setState({
        showPassword: !this.state.showPassword,
    });

    validate = () => {
        const errorMessages = {...this.state.errorMessages};
        const validate = new Validator(this.state, {
            email: 'required|email',
            password: 'required',
        });

        if (validate.fails()) {
            const errors = validate.getErrors();
            errorMessages.email = this.getFirstArrayValue(errors.email);
            errorMessages.password = this.getFirstArrayValue(errors.password);
        } else {
            errorMessages.email = '';
            errorMessages.password = '';
            this.attemptLogin();
        }
        this.setState({ errorMessages, liveErrorCheck: true });
    }

    getFirstArrayValue = (val) => val ? val[0] : '';

    attemptLogin = () => {
        Axios({
            method: "POST",
            data: {
              email: this.state.email,
              password: this.state.password,
            },
            withCredentials: true,
            url: `${process.env.REACT_APP_BACKEND_URL}/login`,
        }).then((res) => {

            let data = res.data;

            if (data.status === 200) {
                
                localStorage.setItem('jwtToken', data.token);
                localStorage.setItem('userId', data.data.id);
                localStorage.setItem('userEmail', data.data.email);
                
                this.props.history.push("/dashboard");
            
            } else if (data.status === 400 || data.status === 404) {
                
            }

        });
    }

    render() {
        return (
            <div className='login-form-container'>
                <form className='login-form form-group'>
                    <div className='login-form-fields'>

                        <BaseInput
                            type='text'
                            name='email'
                            value={ this.state.email }
                            handleInputs={ this.handleInputs }
                            placeholder='Email'
                            errorMessage={ this.state.errorMessages.email }
                        />
                        <BaseInput
                            type={ this.state.showPassword ? 'text' : 'password' }
                            name='password'
                            value={ this.state.password }
                            handleInputs={ this.handleInputs }
                            toggleIcon= { this.togglePassword }
                            placeholder='Password'
                            icon={EyeIcon}
                            iconText='Show'
                            errorMessage={ this.state.errorMessages.password }
                        />
                        <BaseButton
                            buttonText='Sign In'
                            buttonIcon={ ArrowIcon }
                            handleClick={ this.validate } />
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(Form);