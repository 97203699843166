import React from 'react';
import FbIcon from '../../../assets/icons/common/social/light/facebook.svg';
import TwitterIcon from '../../../assets/icons/common/social/light/twitter.svg';
import InstaIcon from '../../../assets/icons/common/social/light/instagram.svg';
import LinkedinIcon from '../../../assets/icons/common/social/light/linkedin.svg';
import FbIconDark from '../../../assets/icons/common/social/dark/facebook.svg';
import TwitterIconDark from '../../../assets/icons/common/social/dark/twitter.svg';
import InstaIconDark from '../../../assets/icons/common/social/dark/instagram.svg';
import LinkedinIconDark from '../../../assets/icons/common/social/dark/linkedin.svg';

export default class SocialLinks extends React.Component {
    render() {
        const { theme } = this.props;

        var icons = {
            fb: FbIcon,
            insta: InstaIcon,
            likedin: LinkedinIcon,
            twitter: TwitterIcon,
        }
        if (theme === 'dark') {
            icons.fb = FbIconDark;
            icons.insta = InstaIconDark;
            icons.twitter = TwitterIconDark;
            icons.likedin = LinkedinIconDark;
        }
        return (
            <div className='social-links'>
                <img src={ icons.fb } alt='icon' />
                <img src={ icons.insta } alt='icon' />
                <img src={ icons.twitter } alt='icon' />
                <img src={ icons.likedin } alt='icon' />
            </div>
        );
    }
}