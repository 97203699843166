import React from 'react';
import '../../assets/styles/auth/login.scss';
import Form from './partials/_loginForm';
import Banner from './partials/_banner';
import SocialLinks from './partials/_socialLinks';
import LogoIcon from '../../assets/icons/common/logo.svg';
import {
    Link, Redirect} from 'react-router-dom';

export default class Login extends React.Component {

    render() {
        return (
            <div>
                <div className='login-container'>
                    <Banner
                        placement={{ left: '70px' }}
                        header='Login to your account'
                        />

                    <div className='login-form-section'>
                        <div className='login-form-head'>
                            <img src={LogoIcon} alt='logo' />
                            <span className='logo-text'>Recapture</span>
                        </div>
                        <div className='login-body'>
                            <div className='login-body-info d-flex flex-column'>
                                <span className='login-page-message'>Need a Recapture account?</span>
                                <Link className='link-to-register' to='/register'>Create an account</Link>
                            </div>
                            <Form />
                        </div>
                        <div className='login-footer'>
                            <Link className='link-to-forgot-password' to='/forgot-password'>Forgot Password?</Link>
                        </div>
                    </div>
                    <div className='mobile-login-links'>
                        <SocialLinks theme='dark' />
                    </div>
                </div>
            </div>
        )
    }
}