import React from 'react';
import { Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const computeRenderProps = props => {
    
    let token = localStorage.getItem('jwtToken');
     
    return localStorage.getItem('jwtToken') ? (
      <Component {...props} />
    ) : (
      props.history.push("/")
    );
  };

  return <Route {...rest} render={computeRenderProps} />;
};


export default PrivateRoute;
