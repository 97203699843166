import React from 'react';
import '../../assets/styles/auth/login.scss';
import Form from './partials/_resetPasswordForm';
import Banner from './partials/_banner';
import SocialLinks from './partials/_socialLinks';
import LogoIcon from '../../assets/icons/common/logo.svg';
import {
    Link, Redirect,
} from 'react-router-dom';
import { withRouter } from 'react-router';

class ResetPassword extends React.Component {
    render() {
        let token = localStorage.getItem('jwtToken');
        if (token) {
            this.props.history.push('/dashboard')
        }
        return (
            <div>
                <div className='login-container'>
                    <Banner
                        placement={{ left: '70px' }}
                        header='Update your password'
                        />

                    <div className='login-form-section'>
                        <div className='login-form-head'>
                            <img src={LogoIcon} alt='logo' />
                            <span className='logo-text'>Recapture</span>
                        </div>
                        <div className='login-body'>
                            <div className='login-body-info d-flex flex-column'>
                                <p className="text-center">Update Password</p>
                            </div>
                            <Form token={this.props.match.params.token} />
                        </div>
                        <div className='login-footer'>
                            <Link className='link-to-forgot-password' to='/'>Login</Link>
                        </div>
                    </div>
                    <div className='mobile-login-links'>
                        <SocialLinks theme='dark' />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ResetPassword);