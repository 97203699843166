import React from 'react';
import Axios from "axios";
import BaseInput from '../../Common/Forms/BaseInput';
import BaseButton from '../../Common/Forms/BaseButton';
import EyeIcon from '../../../assets/icons/common/eyeOpen.svg';
import ArrowIcon from '../../../assets/icons/common/arrow.svg';
const Validator = require('Validator');

export default class Form extends React.Component {
    state = {
        email: '',
        errorMessages: {
            email: '',
        },
        liveErrorCheck: false,
    }

    handleInputs = (name, val) => {
        this.setState({
            [name]: val,
        });
        if (this.state.liveErrorCheck) this.validate();
    }

    validate = () => {
        const errorMessages = {...this.state.errorMessages};
        const validate = new Validator(this.state, {
            email: 'required|email',
        });

        if (validate.fails()) {
            const errors = validate.getErrors();
            errorMessages.email = this.getFirstArrayValue(errors.email);
        } else {
            errorMessages.email = '';
            this.sendEmail();
        }
        this.setState({ errorMessages, liveErrorCheck: true });
    }

    getFirstArrayValue = (val) => val ? val[0] : '';

    sendEmail = () => {
        Axios({
            method: "POST",
            data: {
              email: this.state.email,
            },
            withCredentials: true,
            url: "http://localhost:4000/api/forgot-password",
        }).then((res) => {
            //console.log(res.data);
        });
    }

    render() {
        return (
            <div className='login-form-container'>
                <form className='login-form form-group'>
                    <div className='login-form-fields'>

                        <BaseInput
                            type='text'
                            name='email'
                            value={ this.state.email }
                            handleInputs={ this.handleInputs }
                            placeholder='Email'
                            errorMessage={ this.state.errorMessages.email }
                        />
                        <BaseButton
                            buttonText='Reset Password'
                            buttonIcon={ ArrowIcon }
                            handleClick={ this.validate } />
                    </div>
                </form>
            </div>
        );
    }
}