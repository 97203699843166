import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './components/Auth/Dashboard';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import PrivateRoute from './components/Auth/PrivateRoute';
import AuthRoute from  './components/Auth/AuthRoute';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
            <AuthRoute component={Login} path="/" exact />
            <AuthRoute component={Register} path="/register" exact />
            <PrivateRoute component={Dashboard} path="/dashboard" exact />
            <AuthRoute component={ForgotPassword} path="/forgot-password" exact />
            <AuthRoute component={ResetPassword} path="/reset-password/:token" exact />
          </Switch>
        </Router>
    </div>
  );
}

export default App;
