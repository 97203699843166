import React from 'react';
import { Route } from 'react-router-dom';

const AuthRoute = ({ props, component: Component, ...rest }) => {
  const computeRenderProps = props => {
    return localStorage.getItem('jwtToken') ? (
        props.history.push("/dashboard")
    ) : (
        <Component {...props} />  
    );
  };
  return <Route {...rest} render={computeRenderProps} />;
};

export default AuthRoute;
