import React from 'react';
import Axios from "axios";
import BaseInput from '../../Common/Forms/BaseInput';
import BaseButton from '../../Common/Forms/BaseButton';
import EyeIcon from '../../../assets/icons/common/eyeOpen.svg';
import ArrowIcon from '../../../assets/icons/common/arrow.svg';
const Validator = require('Validator');

export default class RegisterForm extends React.Component {
    state = {
        email: '',
        username: '',
        password: '',
        showPassword: false,
        errorMessages: {
            email: '',
            password: '',
            username: '',
        }
    }

    handleInputs = (name, val) => {
        this.setState({
            [name]: val,
        });
        if (this.state.liveErrorCheck) this.validate();
    }

    togglePassword = () => this.setState({
        showPassword: !this.state.showPassword,
    });

    validate = () => {
        const errorMessages = {...this.state.errorMessages};
        const validate = new Validator(this.state, {
            email: 'required|email',
            password: 'required',
            username: 'required',
        });

        if (validate.fails()) {
            const errors = validate.getErrors();
            console.log(errors);
            errorMessages.email = this.getFirstArrayValue(errors.email);
            errorMessages.password = this.getFirstArrayValue(errors.password);
            errorMessages.username = this.getFirstArrayValue(errors.username);
        } else {
            errorMessages.email = '';
            errorMessages.password = '';
            errorMessages.username = '';
            this.attemptRegister();
        }
        this.setState({ errorMessages, liveErrorCheck: true });
    }

    getFirstArrayValue = (val) => val ? val[0] : '';

    attemptRegister = () => {
        Axios({
            method: "POST",
            data: {
              email: this.state.email,
              username: this.state.username,
              password: this.state.password,
            },
            withCredentials: true,
            url: `${process.env.REACT_APP_BACKEND_URL}/register`,
        }).then((res) => {
            if (res.data === 'success') {
                window.location = "/dashboard";
            } else {
                this.setState({
                    errorMessages: { email: this.state.errorMessages.email = res.data.email }
                });
                this.state.errorMessages.email = res.data.email;
                console.log(res.data.email);
            }
        });
    }

    render() {
        return (
            <div className='register-form-container'>
                <form className='register-form form-group'>
                    <div className='register-form-fields'>

                        <BaseInput
                            type='text'
                            name='email'
                            value={ this.state.email }
                            handleInputs={ this.handleInputs }
                            placeholder='Email'
                            errorMessage={ this.state.errorMessages.email }
                        />
                        <BaseInput
                            type='text'
                            name='username'
                            value={ this.state.username }
                            handleInputs={ this.handleInputs }
                            placeholder='Username'
                            errorMessage={ this.state.errorMessages.username }
                        />
                        <BaseInput
                            type={ this.state.showPassword ? 'text' : 'password' }
                            name='password'
                            value={ this.state.password }
                            handleInputs={ this.handleInputs }
                            toggleIcon= { this.togglePassword }
                            placeholder='Password'
                            icon={ EyeIcon }
                            iconText='Show'
                            errorMessage={ this.state.errorMessages.password }
                        />
                        <BaseButton
                            buttonText='Sign Up'
                            buttonIcon={ ArrowIcon }
                            handleClick={ this.validate } />
                    </div>
                </form>
            </div>
        );
    }
}