import React from 'react';
import '../../../assets/styles/auth/banner.scss';
import GirlIcon from '../../../assets/icons/auth/sketchGirl.svg';
import SocialLinks from './_socialLinks';


export default class Banner extends React.Component {
    render() {
        const { placement, header } = this.props;
        return (
            <div className='banner-container' style={ placement }>
                <h1 className='header'>{ header }</h1>
                <img className='big-icon' src={ GirlIcon } alt='icon' />
                <div className='banner-footer d-flex'>
                    <SocialLinks />
                </div>
            </div>
        );
    }
}