import React from 'react';
import Axios from "axios";
import BaseInput from '../../Common/Forms/BaseInput';
import BaseButton from '../../Common/Forms/BaseButton';
import EyeIcon from '../../../assets/icons/common/eyeOpen.svg';
import ArrowIcon from '../../../assets/icons/common/arrow.svg';
const Validator = require('Validator');

export default class Form extends React.Component {
    state = {
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        errorMessages: {
            password: '',
            confirmPassword: '',
        },
        liveErrorCheck: false,
    }

    handleInputs = (name, val) => {
        this.setState({
            [name]: val,
        });
        if (this.state.liveErrorCheck) this.validate();
    }

    togglePassword = () => this.setState({
        showPassword: !this.state.showPassword,
    });

    toggleConfirmPassword = () => this.setState({
        showConfirmPassword: !this.state.showConfirmPassword,
    });

    validate = () => {
        const errorMessages = {...this.state.errorMessages};
        const validate = new Validator(this.state, {
            password: 'required',
            confirmPassword: 'required',
        });

        if (validate.fails()) {
            const errors = validate.getErrors();
            errorMessages.password = this.getFirstArrayValue(errors.password);
            errorMessages.confirmPassword = this.getFirstArrayValue(errors.confirmPassword);
        } else {
            errorMessages.password = '';
            errorMessages.confirmPassword = '';
            this.updatePassword();
        }
        this.setState({ errorMessages, liveErrorCheck: true });
    }

    getFirstArrayValue = (val) => val ? val[0] : '';

    updatePassword = () => {
        Axios({
            method: "POST",
            data: {
              password: this.state.password,
              confirmPassword: this.state.confirmPassword,
              token: this.props.token,
            },
            withCredentials: true,
            url: "http://localhost:4000/api/reset-password",
        }).then((res) => {
            console.log(res.data);
            if (res.data === 'success') {

            }
        });
    }

    render() {
        return (
            <div className='login-form-container'>
                <form className='login-form form-group'>
                    <div className='login-form-fields'>

                        <BaseInput
                            type={ this.state.showPassword ? 'text' : 'password' }
                            name='password'
                            value={ this.state.password }
                            handleInputs={ this.handleInputs }
                            toggleIcon= { this.togglePassword }
                            placeholder='Password'
                            icon={EyeIcon}
                            iconText='Show'
                            errorMessage={ this.state.errorMessages.password }
                        />
                        <BaseInput
                            type={ this.state.showConfirmPassword ? 'text' : 'password' }
                            name='confirmPassword'
                            value={ this.state.confirmPassword }
                            handleInputs={ this.handleInputs }
                            toggleIcon= { this.toggleConfirmPassword }
                            placeholder='Confirm Password'
                            icon={EyeIcon}
                            iconText='Show'
                            errorMessage={ this.state.errorMessages.confirmPassword }
                        />
                        <BaseButton
                            buttonText='Update Password'
                            buttonIcon={ ArrowIcon }
                            handleClick={ this.validate } />
                    </div>
                </form>
            </div>
        );
    }
}