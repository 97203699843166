import React from 'react';
import '../../../assets/styles/common/forms.scss';

export default class BaseButton extends React.Component {
    render() {
        const { buttonText, buttonIcon, handleClick } = this.props;
        var imgTag = '';
        if (buttonIcon) {
            imgTag = <img className='icon' alt='icon' src={ buttonIcon } />;
        }
        return (
            <div className='base-button-container'>
                <button type='button' className='btn' onClick={ handleClick }>
                    { imgTag }
                    <span>{ buttonText }</span>
                </button>
            </div>
        )
    }
}