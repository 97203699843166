import React from 'react';
import '../../../assets/styles/common/forms.scss';

export default class BaseInput extends React.Component {

    handleChange = (e) => {
        var value = e.target.value;
        this.props.handleInputs(
            this.props.name,
            value
        );
    }

    toggleIcon = (e) => this.props.toggleIcon('trigger');

    render() {
        const {
            type,
            name,
            value,
            placeholder,
            icon,
            iconText,
            errorMessage,
        } = this.props;
        var imgTag = '';
        if (icon) {
            imgTag = <img src={ icon } alt='icon' />;
        }
        return (
            <div className='base-input-container'>
                <input
                    type={ type }
                    name={ name }
                    value={ value }
                    className='base-input'
                    placeholder={ placeholder }
                    onChange={ this.handleChange } />

                <span className='icon' onClick={ this.toggleIcon }>
                    { imgTag }
                    <span>{ iconText }</span>
                </span>

                <span className='error-message'>{ errorMessage }</span>
            </div>
        );
    }
}