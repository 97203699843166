import React from 'react';
import '../../assets/styles/auth/dashboard.scss';
import BaseButton from '../Common/Forms/BaseButton';
import ArrowIcon from '../../assets/icons/common/arrow.svg';
import {
    Link, Redirect,
} from 'react-router-dom';
import { withRouter } from 'react-router';


class Dashboard extends React.Component {
    logout = () => {
        localStorage.removeItem('jwtToken');
        this.props.history.push('/')
    }
    render() {
        return (
            <div>
                <h2>DashBoard</h2>
                <div className="base-button-container">
                <BaseButton
                    buttonText='Log out'
                    buttonIcon={ ArrowIcon }
                    handleClick={ this.logout } />
                </div>
            </div>
        )
    }
}

export default withRouter(Dashboard);