import React from 'react';
import Banner from './partials/_banner';
import Form from './partials/_registerForm';
import '../../assets/styles/auth/register.scss';
import LogoIcon from '../../assets/icons/common/logo.svg';
import SocialLinks from './partials/_socialLinks';
import {
    Link, Redirect,
} from 'react-router-dom';

export default class Register extends React.Component {
    render() {
        return (
            <div>
                <div className='register-container'>
                    <div className='register-form-section'>
                        <div className='register-form-head'>
                            <img src={ LogoIcon } alt='logo' />
                            <span className='logo-text'>Recapture</span>
                        </div>
                        <div className='register-body'>
                            <div className='register-body-info d-flex flex-column'>
                                <span className='register-page-message'>Welcome to Recapture.</span>
                                <span className='register-page-message'>Go beyond email marketing with true marketing automation.</span>
                            </div>

                            <Form />
                            <div className='register-info'>Already have an account?
                                <Link className='link-to-register' to='/'> Log in.</Link>
                            </div>
                            <div className='mobile-register-links'>
                                <SocialLinks theme='dark' />
                            </div>
                        </div>
                    </div>
                    <Banner
                        placement={{ right: '70px' }} />
                </div>
            </div>
        );
    }
}